import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import axios from "axios";
import { Global } from "../../../constants/global"
import { Typography, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@material-ui/core'
import { fba } from "../../../firebaseConfig";
import { obtenerAspirantes } from '../../../servicios/obtenerAspirantes';



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
      },
      container: {
        
        maxHeight: 440,
    },
    tableHead: {
        backgroundColor: '#66abe0b8',
        color: '#FFF',
        
    },
    header: {
       position: 'sticky',
       top: 0,
       backgroundColor: '#6495ED',
    },
    subtitulo: {
        fontSize: "25px",
        color: "#54595F",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
    },
}));
    /* const [columns] = useState([ */
    const columns = [
        { label: 'Nombre', id: 'nombre' },
        { label: 'Primer Apellido', id: 'paterno' },
        { label: 'Segundo Apellido', id: 'materno' },
        { label: 'Estatus', id: 'estatus' },
        { label: 'Correo', id: 'correo' },
        { label: 'Fecha de Nacimiento',type: "date", id: 'fecha_na' },
        { label: 'Estado Civil', id: 'estado_civil' },
        { label: 'RFC', id: 'rfc' },
        { label: 'Genero', id: 'genero' },
        { label: 'Curp', id: 'curp' },
        { label: 'Celular', id: 'celular' },
        { label: 'Telefono', id: 'telefono' },
        { label: 'Pais', id: 'pais_asp' },
        { label: 'Colonia', id: 'colonia' },
        { label: 'Calle', id: 'calle' },
        { label: 'Grado', id: 'grado' },
        { label: 'Institución', id: 'institucion' },
        { label: 'Pais', id: 'pais' },
        { label: 'Estado', id: 'estado' },
        { label: 'Fecha de Inicio', id: 'fecha_ini' },
        { label: 'Fecha de Termino', id: 'fecha_fin' },
        { label: 'Año de Titulación', id: 'titulacion' },
        { label: 'Promedio', id: 'promedio' },
        { label: 'Cedula', id: 'cedula' },
        { label: 'Programa', id: 'programa' },
        { label: 'Nivel', id: 'nivel' },  
        { label: 'Etnia', id: 'etniapersona' },
        { label: 'Respuesta Etnia', id: 'resp_etnia' },
            
    ];
  
  function createData(nombre, paterno, materno, estatus, correo, fecha_na, estado_civil, rfc, genero,
    curp, celular, telefono,  pais_asp, colonia, calle, grado, institucion, pais, estado, fecha_ini,
     fecha_fin,titulacion,promedio,cedula,programa,nivel,etniapersona,resp_etnia) {

    return {nombre, paterno, materno, estatus, correo, fecha_na, estado_civil, rfc, genero,
        curp, celular, telefono, pais_asp, colonia,  calle, grado, institucion, pais, estado, fecha_ini,
         fecha_fin,titulacion,promedio,cedula,programa,nivel,etniapersona,resp_etnia};
    }
function TablaAspirantes() {

    const classes = useStyles();
    const [data, setData] = useState([]);
    const [recarga, setRecarga] = useState(false)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [search, setSearch] = useState("")
    const rows2 = []


    const firestore = fba.firestore();
    const db = firestore.collection('usuarios');

    const searcher = (e) => {
      setSearch(e.target.value)
      console.log('buscar',result)
    }
   
    let result = []
    if (!search)
    {
      result = data
    } else{
      data.filter( (dato) =>
      dato.paterno.toLowerCase().includes(search.toLocaleLowerCase())
      )
    }
   

    useEffect(() => {

        let userFB = fba.auth().currentUser;
        //busqueda en la base de firestore para traer el registro que le pertenesca al correo logueado
        db.where('correo', '==', userFB.email).get().then(snapshot => {
            snapshot.docs.forEach(doc => {
                //Si el posgrado es igual a 0 tiene permisos arriba de un posgrado por lo tanto puede ver todos 
                if (doc.data().posgrado === 0) {
                    //AQUI SE DEBE DE CAMBIAR EL ID DEL PROGRAMA POR EL QUE TENGA EL USUARIO QUE SE LOGUEA
                    
                    axios.get(Global + "aspirante/lista", {
                        headers: {
                            'Access-Control-Allow-Origin': "http://localhost:3000",
                            'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + window.localStorage.getItem('token'),
                        }
                    }).then(response => {
                        //console.log('responsedata',response.data)
                        response.data.map((renglon, index) => {
                        
                            rows2[index] = createData(renglon[1], renglon[2], renglon[3], renglon[4], renglon[5], renglon[6], renglon[7], renglon[8], renglon[9],
                                                       renglon[10], renglon[11], renglon[12], renglon[13], renglon[14], renglon[15], renglon[16], renglon[17], renglon[18],
                                                       renglon[19], renglon[20], renglon[21], renglon[22], renglon[23],renglon[24], renglon[25], renglon[26], renglon[27], renglon[28])
                        })
                        setData(rows2)
                    }).catch(error => console.log(error))
                }
                else {
                    obtenerAspirantes(doc,'aspirante/programas/')
                    
                        .then(programa=>{
                            
                            let aspirantes = [];
                            //console.log('programa',programa)
                            for(let aspirante of programa){
                                aspirantes = aspirantes.concat(aspirante);
                            }
                            aspirantes.map((renglon,index)=>{
                                rows2[index] = createData(renglon[1], renglon[2], renglon[3], renglon[4], renglon[5], renglon[6], renglon[7], renglon[8], renglon[9],
                                    renglon[10], renglon[11], renglon[12], renglon[13], renglon[14], renglon[15], renglon[16], renglon[17], renglon[18],
                                    renglon[19], renglon[20], renglon[21], renglon[22], renglon[23],renglon[24], renglon[25], renglon[26], renglon[27], renglon[28])
                            })
                            setData(rows2)
                            
                        })
                        .catch((err)=>{console.log(err)})
                }
            });
        });


    }, [recarga])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      }
      
    
    return (
        <Paper className={classes.root}>
         <input value={search} onChange={searcher} type="text" placeholder='Search' className='form-control'/>
            <Typography className={classes.subtitulo}>Listado de aspirantes</Typography>
        <TableContainer className={classes.container} >
          <Table >
            <TableHead className={classes.header} > 
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
                {result.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                return (
                    <TableRow >
                    {columns.map((column) => {
                        const value = row[column.id];
                        return (
                        <TableCell >
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                        );
                    })}
                    </TableRow>
                );
                })}
          </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 25, 100]}
          component="div"
          count={rows2.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          
        />
      </Paper>
    )
}
export default TablaAspirantes
