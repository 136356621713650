import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';


const fba = firebase.initializeApp({
    apiKey: "AIzaSyDbEjkKzEgL09oCVFuhcD_T6C5MvrIksn4",
    authDomain: "uach-siip.firebaseapp.com",
    projectId: "uach-siip",
    storageBucket: "uach-siip.appspot.com",
    messagingSenderId: "441525269119",
    appId: "1:441525269119:web:3685463792aca3e6cdc54a",
    measurementId: "G-PQS3H90N9K"

    // Api de produccion
    // apiKey: "AIzaSyCSfm7ItEqSdWDAFP48Wy3nFDxanxWGD8Y",
    // authDomain: "pro-uach-siip.firebaseapp.com",
    // projectId: "pro-uach-siip",
    // storageBucket: "pro-uach-siip.appspot.com",
    // messagingSenderId: "337159289511",
    // appId: "1:337159289511:web:1d81460355b6c190ad3982",
    // measurementId: "G-2XZZVS1YRL"
});
export { fba };